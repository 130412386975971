// @import "../../../styles/App.scss";

.modal {
  bottom: 0;
  right: 0;
  background: rgba(87, 93, 119, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 1;
  overflow: auto;
  padding: 5rem;
  animation: fadeIn 0.2s ease-in both;
  transition: 0.3s all;

  .form {
    &-container {
      box-shadow: none;
    }
  }
}

.modalBlock {
  display: flex;
  background-color: transparent;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  animation: MoveUp 0.5s ease-in-out both;
  transition: 0.3s all;

  &-content {
    width: 100%;
    max-width: 570px;
    background: #fff;
    display: flex;
    overflow: auto;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 7px;
    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12),
      0 3px 6px 0 rgba(0, 0, 0, 0.12);

    .form {
      margin: 2rem auto 0;
    }
  }
}

.input-label {
  color: color(lightGray);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-size: rem-calc(18);
  border-bottom: 2px solid #e0dfdf;
}

.modalWrapper {
  width: 100%;
  max-width: 570px;
}

.modalContainer {
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem 0;

  &-content {
    display: flex-column;
    background-color: color(white);
    width: 100%;

    >.warning {
      font-size: 1.2rem;
    }

    >div {
      display: flex;
      justify-content: space-between;
      font-size: 0.8rem;
      margin: 1rem 0;

    }

    &__block {
      display: flex;
      justify-content: space-between;
      margin: 0 2rem;
      padding-bottom: 1rem;
    }
  }
}

.buttonAction {
  background: #fff;
  padding: 1rem;
  justify-content: center;
  display: flex;
}

.partner-pd {
  padding: 0rem 1.8rem;
}