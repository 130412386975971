.grid-container {
  width: 100%;
  max-width: remCalc(1200);
  margin-left: auto;
  margin-right: auto;
  padding-left: remCalc(24);
  padding-right: remCalc(24);
}

//   height: ${(props) => props.height};
