@import "../../styles/App";

.closeModal {
  right: 10px;
  top: 10px;
  z-index: 400;
  i {
    width: 30px;
    height: 30px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: color(blue);
    color: color(white);
  }
}
.transaction-modal {
  background-color: #fefefe;
  //margin: 15% auto; /* 15% from the top and centered */
  /* padding: 20px; */
  border: none;
  //width: 50%;
  min-height: 400px;
  border-radius: 6rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.user-details {
  background-color: color(blue);
  color: color(white);
  padding: 20px;
  text-transform: capitalize;
  text-align: center;
  border-radius: 0;
  position: relative;

  & ._footer {
    bottom: 10px;
    text-align: center;
    position: absolute;
    width: 90%;
    font-size: 0.8rem;
  }
}

.imgContainer {
  border: 1px solid color(green);
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
}

.user-icon {
  border-radius: 50px;
  border: 2px solid white;
  width: 50px;
  height: 50px;
  padding: 9px;
  top: 1em;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin: 0 auto;
}

.user-svg {
  height: 30px;
  width: auto;
}
.partnerDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    margin: 0.5rem 0 0.5rem;
  }
}
.transaction-details {
  padding: 1rem;
  /* width: 100%; */
  strong {
    font-size: 0.8rem;
  }
}

.trans-detail {
  display: flex;
  align-items: center;
  /* margin-left: -2em; */
  margin-top: 1em;
}
