.error {
  padding: 3px;
  border: 1px solid #ff0000;
  border-radius: 5px;
  background-color: #fff4f4;
  color: #b64645;
  //width: 20%;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}
