@import '../../../styles/App';

.tableContainer {
  background-color: white;
  //border: 1px solid color(faintBlue);
  border-radius: 1px;
  position: relative;
  margin: 2rem 1.1rem 2rem 0;
  overflow-x: auto;
  overflow-y: hidden;

  &__helmet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
  }
  &__helmet-toggle {
    display: flex;
    align-items: center;
    padding: 2px 3px;
    border-bottom: 1px solid color(faintBlue);
    animation: fadeIn 0.4s ease-in both;
    transition: 0.3s all;
    :hover {
      color: color(gray);
    }
    .activeTab {
      background-color: color(blue);
      color: #fff;
      transition: 0.3s all;
    }
  }

  .empty {
    min-height: 200px;
  }

  &__footer {
    padding: 1rem;
    font-size: rem-calc(14);
    border-top: 1px solid color(faintBlue);
    //border-bottom: 1px solid color(faintBlue);

    .control {
      background-color: color(faintBlue);
      padding: 0.3rem;
      color: color(blue);
    }
  }
}

.tableTitle {
  flex: 1;
  font-weight: 400;
  text-transform: capitalize;
  color: #14213d;
  font-size: rem-calc(20);
}

.tableTitle__toggle {
  cursor: pointer;
  color: #ccc;
  border-radius: 3px;
  font-weight: 400;
  padding: 5px 50px;
  text-transform: capitalize;
  font-size: rem-calc(16);
  transition: all 0.4s ease-in-out;
}
.tableSearch {
  height: 36px;
  border-radius: 10px;
  position: relative;
  background-color: rgba(41, 39, 36, 0.06);
  display: flex;
  align-items: center;
  padding-left: 17px;
  img {
    opacity: 0.2;
  }
}

#searchForm {
  width: 100%;
  height: 100%;
  font-weight: 200;
  font-size: 16px;
  padding: 8px 15px 8px 5px;
  background-color: transparent;
  border-radius: inherit;
  border: none;
  text-indent: unset;
  outline: none;

  &::placeholder {
    color: rgba(41, 39, 36, 0.3);
  }
}

table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;

  .tableTitle {
    font-weight: 400;
    text-transform: capitalize;
    font-size: rem-calc(12);
  }

  .tableBody {
    .tableRow {
      background-color: white;

      &:hover {
        background-color: #eff5fc;
      }
    }
  }
  .tableHeading {
    .tableHeader {
      padding: 16px;
      text-align: left;
      background-color: #eff5fc;
      box-shadow: #bdcfe4 0px -1px inset;
    }
  }

  .tableBody {
    .tableRow {
      cursor: pointer;
    }

    .tableData {
      box-shadow: rgb(227, 232, 238) 0px -1px inset;
    }

    .tableItems {
      padding: 16px;
      font-size: rem-calc(12);
      overflow-wrap: break-word;
    }
  }
}

.action-bg {
  cursor: pointer;
  background-color: #eff5fc;
  box-shadow: #bdcfe4 0px -1px inset;
}

.status {
  display: flex;
  min-width: 90px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.statusSuccess {
  background-color: color(green);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.statusFail {
  background-color: color(red);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.statusPending {
  background-color: color(yellow);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.activeTab {
  background-color: color(blue);
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.option-bg {
  background-color: #eff5fc;
}

.action-item {
  padding: 10px 10px;
  &:hover {
    background-color: color(green);
    color: #fff;
    border-radius: 2px;
  }
}

.boxContainer {
  position: relative;

  &:hover .action-box {
    opacity: 1;
    position: absolute;
    width: 105px;
    left: -78px;
    color: #707070;
    top: 15px;
    box-shadow: rgba(136, 152, 170, 0.1) 0px 0px 0px 1px,
      rgba(49, 49, 93, 0.1) 0px 15px 35px 0px,
      rgba(0, 0, 0, 0.13) 0px 5px 15px 0px;
    background: white;
    border-radius: 4px;
    z-index: 1;
  }

  .action-box {
    border-radius: 2px;
    position: absolute;
    z-index: -1;
    opacity: 0;
    // visibility: hidden;
    transition: 0.3s opacity ease-in-out;
  }
}
