@font-face {
    font-family: 'TT Hoves';
    src: url('TT-Hoves-Light-Italic.otf') format('otf'),
        url('TTHoves-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves';
    src: url('TT-Hoves-Black-Italic.otf') format('otf'),
        url('TTHoves-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves';
    src: url('TT-Hoves-Black.otf') format('otf'),
        url('TTHoves-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves';
    src: url('TT-Hoves-Light.otf') format('otf'),
        url('TTHoves-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves';
    src: url('TT-Hoves-Medium-Italic.otf') format('otf'),
        url('TTHoves-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves';
    src: url('TT-Hoves-Medium.otf') format('otf'),
        url('TTHoves-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves';
    src: url('TT-Hoves-Italic.otf') format('otf'),
        url('TTHoves-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves';
    src: url('TT-Hoves-Regular.otf') format('otf'),
        url('TTHoves-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hoves';
    src: url('TT-Hoves-Bold-Italic.otf') format('otf'),
        url('TTHoves-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'TT Hoves';
    src: url('TT-Hoves-Bold.otf') format('otf'),
        url('TTHoves-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
