@import "../../../styles/App";
.spinner {
  border: .5rem solid color(lightAsh); /* Light grey */
  border-top: .5rem solid color(lightBlue); /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.spinner-small {
  width: 20px;
  height: 20px;
}

.spinner-white {
  border: 3px solid #ffffff;
  border-top: .5rem solid color(light);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
