@import "../../../styles/App";

.steps {
  display: flex;
  width: 266px;
  margin: 3rem auto 2rem;
  justify-content: center;

  &-item {
    margin-right: 1rem;
    background-color: color(green);
    width: 4rem;
    height: 8px;
  }
}

.disabled {
  opacity: 0.4;
}
