@import "../../../styles/App";

.content {
  grid-area: content;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
  background-color: color(fadedBlue);
  .profile__section {

    h6{
      font-weight: normal;
    }
    p {
      text-align: right;
      font-size: .7rem;
      color: color(green);
    }
  }
  .lang__text{
    font-size: .8rem;
    margin-right: .3rem;
  }
  .avatar{
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    margin: 0 .5rem;
    border-radius: 50%;
    background:color(blue);
  }
}
