/* ABSTRACT */
@import './abstract/colors';
@import './abstract/utils';
@import './abstract/breakpoints';

/* LAYOUT */
@import './layout/flex';
@import './layout/grid';

/* FONTS */
@import '../assets/fonts/tt_hoves/tt_hoves';

/* BASE */
@import './base/resets';

/*utility classes*/
.pointer {
  cursor: pointer;
}

.bold {
  font-weight: 700;
}

.bold-header {
  font-size: rem-calc(20);
  font-weight: 700;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2.8rem;
}

.overflow-auto {
  overflow: auto;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-0 {
  margin-top: 0;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.fs-1 {
  font-size: 0.7rem;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.blue {
  color: color(blue);
}

.height-400 {
  height: 400px;
}

.min-width-100 {
  min-width: 80px;
}

.mt-2 {
  margin-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes MoveUp {
  from {
    transform: translateY(40px);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

hr {
  background-color: color(lightAsh);
  margin: 1rem 0;
}

.min-width-100 {
  min-width: 70px;
}

.ml-0 {
  margin-left: 0;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mg-03 {
  margin: 0 3em;
}

.br-50 {
  border-radius: 2rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.p-2 {
  padding: 2rem;
}

.mw-50-pCent {
  min-width: 50%;
}

.mx-auto {
  margin: 0 auto;
}

.fs-0-7 {
  font-size: 0.7rem;
}

.fs-0-9 {
  font-size: 0.9rem;
}

.px-2 {
  padding: 0 2rem;
}

.mh-vh {
  height: 600px;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

select:focus,
input[type='text']:focus,
input[type='name']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='checkbox']:focus,
input[type='password']:focus {
  outline: none;
  // background-color: #fff;
  border: 1px solid #386b4c;
  border-radius: 5px;
}

//colors
.lightGray {
  color: color(lightGray);
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed !important;
}

// .statusSuccess {
//   background-color: #386B4C;
//   height: 10px;
//   width: 10px;
//   border-radius: 50%;
// }

.green {
  color: #386b4c;
}

.red {
  color: #dd0000;
}

.yellow {
  color: #fcb845;
}

.popup {
  height: auto;
  width: 100%;
  overflow: auto;
  top: 70px;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px, rgba(0, 0, 0, 0.1) 0px 15px 35px,
    rgba(50, 50, 93, 0.1) 0px 50px 100px;
  background-color: white;
  border-radius: 5px;
  z-index: 2;
  animation: 0.2s ease-in-out 0s 1 normal both running fadeIn;
  opacity: 0;

  &__item {
    padding: 3px !important;

    &:hover {
      background-color: #eff6fd;
    }

    > p {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 25px;
      display: flex;
      align-items: center;
      margin: 0.5rem;
      cursor: pointer;
    }
  }
}
