@import "../../../styles/App";

%button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem-calc(16);
  cursor: pointer;
  background-color: color(green);
  text-transform: uppercase;
  cursor: pointer;
  margin: 0 10px;
  max-height: rem-calc(40);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  //padding: 10px 20px;
  font-size: rem-calc(14);
  border: 1px solid transparent;
  color: color(white);
  img {
    margin-right: 0.5rem;
    width: rem-calc(12);
  }

  &:hover,
  &:active {
    opacity: 0.8;
    border: 1px solid #d8e3ff;
  }
}

.button {
  &--green {
    @extend %button;
    background-color: color(green);
    border: 1px solid color(green);
  }
  &--red {
    @extend %button;
    background-color: color(red);
    border: 1px solid color(red);
  }
  &--blue {
    @extend %button;
    background-color: color(blue);
    border: 1px solid color(blue);
  }
  &--sec-blue {
    @extend %button;
    background-color: color(secondaryBlue);
    border: 1px solid color(secondaryBlue);
  }

  &--blue-text {
    @extend %button;
    color: color(blue);
    background-color: transparent;
    box-shadow: none;
  }
  &--yellow-text {
    @extend %button;
    color: color(yellow);
    background-color: transparent;
    box-shadow: none;
  }
  &--red-text {
    @extend %button;
    background-color: transparent;
    box-shadow: none;
    color: color(red);
  }
  &--green-text {
    @extend %button;
    box-shadow: none;
    background-color: transparent;
    color: color(green);
  }

  &--outline {
    background-color: transparent;
    text-transform: capitalize;
    margin: 0 10px;
    box-shadow: none;
    text-decoration: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #d8e3ff;
    color: color(lightBlue);
  }

  &--outline-red {
    background-color: transparent;
    text-transform: capitalize;
    margin: 0 10px;
    box-shadow: none;
    text-decoration: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid color(red);
    color: color(red);
  }

  &--sm {
    padding: 5px 10px;
    font-size: rem-calc(13);
  }
}

.buttonIcon {
  margin-right: rem-calc(6);
}

.center-button {
  display: flex;
  justify-content: center;
  margin: 1rem;
}
