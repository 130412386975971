@import "../../../styles/App";
.infoBox {
  padding: 2rem;
  background-color: color(white);
  border-radius: 0.5rem;
  border: 1px solid color(faintBlue);
  // min-height: rem-calc(300);
  height: fit-content;

  strong {
    color: color(lightGray);
    font-weight: normal;
    font-size: .8rem;
  }

  & .empty {
    margin-top: 4rem;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    strong {
      color: color(blue);
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  &__body {
    div {
      display: flex;
      justify-content: space-between;
      font-size: 0.8rem;
      margin: 1rem 0;

      > button {
        background: transparent;
        border: none;
        color: color(blue);
        text-transform: uppercase;
        cursor: pointer;

      }

      >img {
        width:  50px;
        height: 50px;
        cursor: pointer;
      }
    }
    span:first-of-type {
      color: color(lightGray);
      margin-right: 1rem;
    }
  }
}
.partnerBox {
  width: 500px;
  margin: 2rem auto 5rem;
}
