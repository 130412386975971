@import "../../../styles/App";

.sideMenu {
  grid-area: sideMenu;
  background-color: color(blue);
  overflow-y: auto;
  padding: 1rem;

  .sideMenu__logo {
    width: rem-calc(150);
    margin-left: -0.7rem;
  }
  .sideMenu__title {
    color: color(white);
    font-weight: 700;
    font-size: 0.8rem;
  }
  .sideMenu__subHeader {
    color: color(lightWhite);
    text-transform: uppercase;
    padding: 1rem 0;
    font-size: 0.7em;
  }
  .sideMenu__list {
    margin-top: 1rem;
  }
  .sideMenu__item {
    display: flex;
    font-size: 0.9em;
    font-weight: normal;
    padding: 0.5rem 0;
    a,
    span {
      cursor: pointer;
      color: color(white);
      text-decoration: none;
    }
    .active {
      color: color(yellow);
    }
    img {
      margin-right: 1rem;
    }
  }
}
