.back {
  margin: 2rem;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #14213d;
  font-size: 1.1rem;
}

.container {
  display: grid;
  grid-template-columns: 1fr 5fr;
  margin: 0rem 2rem;
  gap: 2rem;

  &__station {
    > p {
      color: #999999;
      font-size: 0.8rem;
    }

    > h5 {
      color: #000000;
    }

    &-card {
      margin-top: 1em;

      > .card {
        width: 100%;

        > .flex-column {
          > h4 {
            letter-spacing: 5px;
            font-size: 1em;
          }
        }
      }
    }
  }

  &__general {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 2em;

    &-products {
      &--title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        > button {
          background-color: transparent;
          background-repeat: no-repeat;
          border: none;
          cursor: pointer;
          overflow: hidden;
          outline: none;
          color: #14213d;
          font-size: 0.75rem;
        }
      }

      &--item {
        background-color: #f4f2f0;
        border: 1px solid #d4e5f9;
        border-radius: 5px;
        margin-bottom: 1rem;

        &-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 1rem;
          padding: 1rem 0.1rem;

          &--name {
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 1rem;
            align-items: center;

            > span {
              display: flex;
              align-items: center;
              background-color: #000000;
              color: #ffffff;
              font-size: 0.7em;
              padding: 0.7rem 0.5rem;
              border-radius: 5px;
              height: 15px;
              width: fit-content;
            }
          }

          &--actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            > button {
              background-color: transparent;
              background-repeat: no-repeat;
              border: none;
              cursor: pointer;
              overflow: hidden;
              outline: none;
              margin-right: 1rem;
              letter-spacing: 2px;
              font-size: 0.6rem;
              font-weight: bold;

              &.active {
                color: #14213d;
              }

              &.deactive {
                color: #dd0000;
              }

              &.activate {
                color: #386b4c;
              }
            }
          }
        }

        &-bottom {
          display: grid;
          margin: 1rem 3rem;
          grid-template-columns: repeat(3, 1fr);
          gap: 1rem;

          &--price {
            color: #707070;

            > div {
              border: 1px solid #dddbd7;
              margin-top: 0.5rem;
              border-radius: 5px;
              padding: 5px;
              text-align: left;
            }
          }

          &--amount {
            > small {
              color: #707070;
            }

            > div {
              background-color: #14213d;
              color: #ffffff;
              margin-top: 0.5rem;
              border-radius: 5px;
              padding: 5px;
              text-align: left;
            }
          }

          &--qty {
            > small {
              color: #707070;
            }

            > div {
              background-color: #14213d;
              color: #ffffff;
              margin-top: 0.5rem;
              border-radius: 5px;
              padding: 5px;
              text-align: left;
            }
          }
        }
      }

      &--pending {
        background-color: #fff6e6;
        border: 1px solid #f9d184;
        border-radius: 5px;
        margin-bottom: 1rem;

        &-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 1rem;
          padding: 1rem 0.1rem;

          &--name {
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 1rem;
            align-items: center;

            > span {
              display: flex;
              align-items: center;
              background-color: #000000;
              color: #ffffff;
              font-size: 0.7em;
              padding: 0.7rem 0.5rem;
              border-radius: 5px;
              height: 15px;
            }
          }

          &--actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            > button {
              background-color: transparent;
              background-repeat: no-repeat;
              border: none;
              cursor: pointer;
              overflow: hidden;
              outline: none;
              margin-right: 1rem;
              letter-spacing: 2px;
              font-size: 0.6rem;
              font-weight: bold;

              &.active {
                color: #14213d;
              }

              &.deactive {
                color: #dd0000;
              }

              &.cancel {
                color: #fcb845;
              }

              &.activate {
                color: #386b4c;
              }
            }
          }
        }

        &-bottom {
          display: grid;
          margin: 1rem 3rem;
          grid-template-columns: repeat(3, 1fr);
          gap: 1rem;

          &--price {
            color: #707070;

            > div {
              border: 1px solid #dddbd7;
              margin-top: 0.5rem;
              border-radius: 5px;
              padding: 5px;
              text-align: left;
            }
          }

          &--amount {
            > small {
              color: #707070;
            }

            > div {
              background-color: #14213d;
              color: #ffffff;
              margin-top: 0.5rem;
              border-radius: 5px;
              padding: 5px;
              text-align: left;
            }
          }

          &--qty {
            > small {
              color: #707070;
            }

            > div {
              background-color: #14213d;
              color: #ffffff;
              margin-top: 0.5rem;
              border-radius: 5px;
              padding: 5px;
              text-align: left;
            }
          }

          &--update {
            margin: 1rem;
            color: #707070;

            > div {
              border: 1px solid #dddbd7;
              margin-top: 0.5rem;
              border-radius: 5px;
              padding: 5px;
              text-align: left;
            }
          }
        }

        &-update {
          display: grid;
          align-items: center;
          grid-template-columns: 0.7fr 1fr;
          margin: 0 3.2rem;
          margin-bottom: 1rem;
          grid-gap: 1rem;

          &--rate {
            color: #fcb845;

            > div {
              border: 1px solid #fcb845;
              margin-top: 0.5rem;
              border-radius: 5px;
              padding: 5px;
              text-align: left;
              color: #707070;
            }
          }

          > .pending {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-top: 1.5rem;

            > p {
              font-size: 0.8rem;
              color: #3c3c3c;
            }

            > img {
              height: 15px;
            }
          }
        }
      }
    }
  }
}
