@import "../../../styles/App";

.form {
  display: flex;
  width: 500px;
  margin: 2rem auto 5rem;
  flex-direction: column;
  transition: all 0.5s ease-in;
  .cancel {
    font-size: rem-calc(14);
    cursor: pointer;
  }
  .save {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.3rem;
    color: color(green);
  }

  &-container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
    //border: 1px solid color(faintBlue);
    position: relative;

    > img {
      width: 200px;
      height: 200px;
    }
    & small {
      color: color(lightGray);
      padding-bottom: 0em;
      font-size: 0.8em;
    }

    & input[type="radio"] ~ small {
      color: color(lightGray);
      padding-bottom: 0em;
      font-size: 0.8em;
    }

    &__heading {
      display: flex;
      justify-content: space-between;
      color: color(black);
      align-items: center;
      margin: 1rem 2rem 0rem;
      padding-bottom: 1rem;
      border-bottom: 0.1px solid #ccc;
    }
    &__footer {
      margin: 2rem;
      font-size: rem-calc(14);
      color: color(green);
      img {
        width: 1rem;
      }
      img:first-of-type {
        margin-right: 0.5rem;
      }
      img:last-of-type {
        margin-left: 0.5rem;
      }
    }

    &__content {
      padding: 1rem 2rem 2rem;
        > img {
          width: 300px;
          padding: 1rem 2rem 2rem;
        }
      

      button p {
        color: color(white);
      }
      p {
        font-size: rem-calc(14);
        color: color(lightGray);
      }

      & .radio {
        font-size: 1.1rem;
        margin-bottom: 1rem;
        cursor: pointer;

        input {
          margin-right: 0.5rem;
        }
      }

      &Input {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom: 1em;

        & .react-tel-input .form-control {
          width: 100%;
          padding: 1.3rem 3rem;
        }
        & small {
          color: color(lightGray);
          padding-bottom: 0.5em;
          font-size: 0.8em;
        }
        & select,
        input {
          border: 1px solid color(faintBlue);
          padding: 1em;
          outline: none;
          border-radius: 5px;
        }
        input::placeholder {
          background-color: transparent;
        }
      }
    }
  }
  &-actions {
    margin-top: 1rem;
    padding: 10px;
  }
}
.button__disabled {
  opacity: 0.5;
}

.half-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.red {
  color: color(red);
}
.form-container__contentInput .react-tel-input .form-control,
.react-tel-input .flag-dropdown {
  border: 1px solid color(faintBlue) !important;
}

/* Customize the label (the container) */
.checkBoxContainer {
  display: flex;
  position: relative;
  padding-left: 35px;
  align-items: center;
  margin: 1rem 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  small {
    margin-right: 1rem;
    margin-top: 0.2rem;
    font-size: rem-calc(14);
  }
}

/* Hide the browser's default checkbox */
.checkBoxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkBoxContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkBoxContainer input:checked ~ .checkmark {
  background-color: color(green);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkBoxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkBoxContainer .checkmark:after {
  left: 9px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

//Style radio button with green border-radius
.radio-button {
  position: relative;

  &_item input[type="radio"] {
    position: absolute;
    visibility: hidden;
  }
  & label {
    display: block;
    position: relative;
    // font-weight: 300;
    color: color(lightGray);
    font-size: 0.8em;
    margin: 10px auto;
    height: 30px;
    z-index: 9;
    padding-left: 2rem;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
  }
}

.radio-button_item:hover label {
  color: color(green);
}

.radio-button_item .check {
  display: block;
  position: absolute;
  border: 2px solid #aaaaaa;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  top: 0;
  left: 0;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.radio-button_item:hover .check {
  border: 2px solid color(green);
}

.radio-button_item .check::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 9px;
  width: 9px;
  top: 2px;
  left: 3px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type="radio"]:checked ~ .check {
  border: 2px solid color(green);
}

input[type="radio"]:checked ~ .check::before {
  background: color(green);
}

input[type="radio"]:checked ~ span {
  color: color(green);
}

// input[type="radio"]:checked ~ label {
//   color: #0dff92;
// }
