@import "../../../styles/App";

.navBg {
  background-color: color(white);
  position: sticky;
  z-index: 1;
  top: 0;
}

.navBar {
  background-color: color(white);
  cursor: pointer;
  border-radius: 0.5rem;
  align-items: center;
  border: 1px solid rgb(218, 227, 238);
  padding: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
  position: sticky;
  z-index: 1;
  top: 0;
  .navBar__title {
    color: color(subtleGray);
    text-transform: uppercase;
    font-weight: normal;
  }
  .navBar__nav {
    display: flex;
    width: rem-calc(350);
  }
  .navBar__navItem {
    margin: 0 0 0 2rem;
    align-items: center;
    display: flex;
    .navBar__dropDown {
      position: relative;

      .navBar__dropDownMenu {
        position: absolute;
        list-style-type: none;
        opacity: 0;
        top: rem-calc(30);
        right: -25px;
        background-color: color(white);
        visibility: hidden;
        border-radius: 0.5rem;
        overflow: hidden;
        z-index: 5;
        box-shadow:0 5px 10px rgba(0, 0, 0, 0.01);
        a,
        span {
          padding: 0.5rem 1rem;
          text-decoration: none;
          color: color(blue);
          display: block;
        }
        li:hover,
        li.active {
          background-color: color(faintBlue);
        }
      }
      .notification {
        width: 300px;
        padding: 0;
        li {
          padding: 1rem;
          small {
            font-size: 0.6rem;
            color: color(subtleGray);
          }
        }

        li:first-child,
        li:last-child {
          text-align: center;
          background-color: color(faintBlue);
          padding: 1rem;
          a {
            font-size: 0.8rem;
            text-decoration: none;
            color: color(blue);
          }
        }
      }
    }
    .navBar__dropDown:hover {
      .navBar__dropDownMenu {
        position: absolute;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
