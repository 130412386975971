// @import "../../styles/App";
//utility
.mb-1{
  margin-bottom: 1rem;
}
//vendor panel

.panel{
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-column-gap: 1rem;
}
.panel__card{
  grid-template-rows: 1fr 2fr;
}

.form.addVendorForm{
  margin: 0;
}


.station__details{
  padding: 2rem;
  > div{
    margin-bottom: 2rem;
  }
}
.input__modalRate{
  padding: .6rem;
  margin-bottom: 1rem;
}

