@import "../../../styles/App";
.asideMenu {
  margin-top: 2rem;
  &__heading {
    margin-bottom: 1rem;
    small {
      color: color(lightGray);
    }
  }
  &__body {
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      a {
        color: color(blue);
        text-decoration: none;
        font-size: rem-calc(14);
      }
      img {
        margin-right: 1rem;
        width: rem-calc(20);
      }
    }
    li:last-of-type{
      margin-bottom: 1rem;
    }
  }
}
