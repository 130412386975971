@import "../../../styles/App";

.dropdown {
  z-index: 1;
  background-color: #fff;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-width: 430px;
  max-height: 256px;
  position: relative;
  border: 1px solid color rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12),
    0 3px 6px 0 rgba(0, 0, 0, 0.12);
  position: absolute;

  &__item {
    cursor: pointer;
    // border-bottom: 1px solid #ccc;
    padding: 0.5rem;
    &:hover {
      background-color: color(green);
      color: #fff;
      box-shadow: 0 0 1px color(green);
    }
  }
}

.topHack {
  top: 5rem;
}
