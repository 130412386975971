.filterBox {
  background-color: white;
  border: 1px solid rgb(218, 227, 238);
  padding: 1em;
  width: 57%;
  margin-top: 0.7em;

  &_buttons {
    > .filters {
      display: grid;
      grid-template-columns: 0.6fr repeat(3, 1fr);
      gap: 0.5em;
      margin-bottom: 1em;

      > p {
        color: #475f7b;
        font-size: 0.95em;
      }

      > button {
        height: 30px;
        width: 120px;
        background-color: #ffffff;
        border: 1px solid #d4e5f9;
        cursor: pointer;
        border-radius: 5px;
        color: #0d1e4f;

        &:active,
        &:focus {
          background-color: #0d1e4f;
          color: #ffffff;
        }
      }
    }

    > .input-form {
      margin-left: 5em;

      > input,
      > select {
        width: 100%;
        border: 1px solid #dddbd7;
        border-radius: 5px;
        padding: 0.5em;
        // padding-inline-start: 2em;
      }

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.3em;

        > span {
          color: #dddbd7;
        }

        > input,
        > select {
          width: 100%;
          border: 1px solid #dddbd7;
          border-radius: 5px;
          padding: 0.5em;
        }
      }
    }
  }

  &_content {
    > div {
      font-size: 0.75em;
      background-color: #535353;
      color: #ffffff;
      border-radius: 10px;
      padding: 2px 8px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      width: fit-content;

      &:last-child {
        margin-top: 1.2em;
      }

      > span {
        background-color: #ffffff;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        color: #535353;
        cursor: pointer;
        padding: 2px 5px;
        font-size: 0.7em;
        font-weight: bolder;
        margin-left: 1em;
      }
    }

    > .filterBtns {
      background-color: #ffffff;
      display: grid;
      justify-content: space-between;
      grid-template-columns: 1fr 1.2fr;
      grid-gap: 0.1em;
      margin-left: -0.4em;

      > button {
        background-color: #386b4c;
        border: none;
        color: #ffffff;
        border-radius: 5px;
        padding: 5px;
        cursor: pointer;

        &:last-child {
          background-color: transparent;
          color: #14213d;
          margin-right: -0.8em;
        }
      }
    }
  }
}

input[type='date']:before {
  color: #dddbd7;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

input[type='date']:focus:before {
  content: '' !important;
}

select:not(:valid) {
  color: #dddbd7;
}
