@import '../../../styles/App';

.card {
  background-color: #15467d;
  width: rem-calc(200);
  display: flex;
  text-transform: capitalize;
  padding: 1em;
  border-radius: 8px;
  margin-right: 1rem;
  font-size: rem-calc(16);
  color: color(white);
  transform: translateY(0);
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  img {
    margin-right: 1rem;
  }
  h4 {
    font-size: rem-calc(20);
    font-weight: normal;
  }
  small {
    font-size: rem-calc(10);
  }
}

.card:hover {
  transform: translateY(-5px);
}

.green-card {
  background-color: color(green);
}

.red-card {
  background-color: color(red);
}
.purple-card {
  background-color: #7986cb;
}
.yellow-card {
  background-color: color(yellow);
}

.blue-card {
  background-color: color(blue);
}

.light-blue-card {
  background-color: #b5ccfa;
}

.iconCard {
  background-color: #14213d;
}
.torquoise-card {
  background-color: #b5ccfa;
}

.width-adjusted {
  width: 9rem;
  height: fit-content;
}
