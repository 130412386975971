@import "../../../styles/App";
.imageUploader {
  //height: rem-calc(200);
  background-color: color(white);
  border-radius: 0.5rem;
  border: 1px solid color(faintBlue);
  padding: 2rem;
  position: relative;
  > img {
    width: 150px;
    height: 150px;
  }
  &__circle {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 3px solid color(lightAsh);

    > img {
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
  }
  &__greenButton {
    position: absolute;
    top: 75%;
    width: rem-calc(40);
    height: rem-calc(40);
    border-radius: 50%;
    right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: color(green);

  }
  &__fileInput {
    opacity: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 5;
    bottom: 0;
    top: 0;
    position: absolute;
  }
}
