@import "../../styles/App";

.login {
  background-color: color(fadedBlue);
  min-height: 100vh;
  position: relative;
}
.form__container {
  margin:0;

  img {
    width: rem-calc(150);
    display: block;
    margin: 0 auto;
  }
  p {
    text-align: center;
    text-transform: uppercase;
    color: color(yellow);
  }
  button{
    margin: 0;
  }
  button p{
    color: color(white);
  }
}
.form__login {
  padding: 0 2rem;
  max-width: rem-calc(450);
  a{
    color: color(secondaryBlue);
    text-decoration: none;
    cursor: pointer;
    font-size: .8rem;
  }
}
