@import "../../../styles/App";

.footer {
  background-color: color(blue);
  color: color(white);
  width: 100%;
  display: flex;
  padding: 1rem;
  justify-content: space-evenly;
  position: absolute;
  font-size: .8rem;
  bottom: 0;
  ul {
    list-style-type: none;
    display: flex;
    li {
      margin-right: 1rem;
    }
  }
}
